
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IFormacaoPrecoGrupo, IFormacaoPrecoGrupoItem } from '@/models/Entidades/Cadastros/Precificacao/IFormacaoPreco';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { ETipoValorFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoValorFinanceiro';
import Card from '@/core/components/Tela/Card.vue';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';

export default defineComponent({
  name: 'FormacaoPrecoGrupoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    grupo: {
      type: Object as () => IFormacaoPrecoGrupo,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    MensagemSemDados,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      grupo: {} as IFormacaoPrecoGrupo,
      colunas: [] as IColumn[],
      indexSelecionado: -1,
      descricaoCusto: '',
      valorCusto: 0,
    });

    function preencherColunas() {
      state.colunas = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'codigo', position: 1, visible: false, ordering: true,
        },
        {
          title: 'Custo', dataIndex: 'descricao', key: 'descricao', position: 2, visible: true, ordering: true,
        },
        {
          title: 'Valor', dataIndex: 'valor', key: 'valor', position: 3, visible: true, ordering: true, align: 'right',
        },
        {
          title: 'Ações', key: 'acoes', position: 4, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
        },
      ];
    }

    function limparTela() {
      state.grupo = {} as IFormacaoPrecoGrupo;
      state.grupo.tipoValor = ETipoValorFinanceiro.Percentual;
      state.grupo.itens = [] as IFormacaoPrecoGrupoItem[];
      state.indexSelecionado = -1;
      state.descricaoCusto = '';
      state.valorCusto = 0;
      preencherColunas();
    }

    function inserirItem() {
      if (state.descricaoCusto === '' || state.descricaoCusto === undefined) {
        apresentarMensagemSucesso('A descrição do custo deve ser informado!');
        return;
      }

      if (state.valorCusto === 0) {
        apresentarMensagemSucesso('O valor do custo deve ser informado!');
      }

      if (state.indexSelecionado === -1) {
        const item = {} as IFormacaoPrecoGrupoItem;
        item.descricao = state.descricaoCusto;
        item.valor = state.valorCusto;
        state.grupo.itens.push(item);
      } else {
        state.grupo.itens[state.indexSelecionado].descricao = state.descricaoCusto;
        state.grupo.itens[state.indexSelecionado].valor = state.valorCusto;
      }
      state.indexSelecionado = -1;
      state.descricaoCusto = '';
      state.valorCusto = 0;
    }

    function alterarItem(index: number) {
      state.indexSelecionado = index;
      state.descricaoCusto = state.grupo.itens[index].descricao;
      state.valorCusto = state.grupo.itens[index].valor;
    }

    function excluirItem(index: number) {
      state.grupo.itens.splice(index, 1);
    }

    async function salvar() {
      emit('confirmar', state.grupo);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        state.grupo = props.grupo;
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioMascara,
      alterarItem,
      inserirItem,
      excluirItem,
      salvar,
    };
  },
});
