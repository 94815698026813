import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e451f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xl-20 ant-col-xs-24" }
const _hoisted_3 = { class: "ant-col ant-col-xl-4 ant-col-xs-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xl-24 ant-col-xs-24 ss-div-grupos" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "ant-row" }
const _hoisted_11 = { class: "ant-col ant-col-xl-24 ant-col-xs-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_formacao_preco_grupo_modal = _resolveComponent("formacao-preco-grupo-modal")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      centered: "",
      width: 900,
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      "wrap-class-name": "ss-modal-cadastros"
    }, {
      title: _withCtx(() => [
        _createTextVNode("Cadastro de Formação de Preço")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            type: "button",
            class: "ant-btn ant-btn-lg ant-btn-secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.salvar(true)))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(false)))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_a_form_item, {
                      label: "Descrição",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "250",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.formacaoPreco.descricao) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.state.formacaoPreco.descricao]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      label: "Margem de Lucro",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          valor: _ctx.state.formacaoPreco.margemLucro,
                          "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.formacaoPreco.margemLucro) = $event)),
                          percentual: true
                        }, null, 8, ["valor"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_card, { titulo: "Grupos" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.formacaoPreco.grupos, (grupo, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "ss-div-grupo",
                            key: index
                          }, [
                            _createVNode(_component_card, {
                              class: "ss-card-grupo",
                              titulo: grupo.descricao
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  class: "ss-botao-alterar",
                                  onClick: ($event: any) => (_ctx.abrirGrupo(index))
                                }, [
                                  _createVNode(_component_icone, { nome: "editar" })
                                ], 8, _hoisted_6),
                                _createElementVNode("a", {
                                  class: "ss-botao-excluir",
                                  onClick: ($event: any) => (_ctx.excluirGrupo(index))
                                }, [
                                  _createVNode(_component_icone, { nome: "excluir" })
                                ], 8, _hoisted_7),
                                _createVNode(_component_a_table, {
                                  columns: _ctx.state.colunas.filter(item => item.visible),
                                  "data-source": grupo.itens,
                                  rowKey: "codigo",
                                  pagination: false,
                                  scroll: { y: 200 },
                                  size: "small",
                                  rowClassName: "ss-table-campos"
                                }, {
                                  emptyText: _withCtx(() => [
                                    _createVNode(_component_mensagem_sem_dados, { mensagem: "Nenhum item adicionado ainda" })
                                  ]),
                                  bodyCell: _withCtx(({ column, text, }) => [
                                    (column.key === 'valor')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(grupo.tipoValor === 1 ? _ctx.UtilitarioMascara.mascararValor(text, 2) + '%' : 'R$ ' + _ctx.UtilitarioMascara.mascararValor(text, 2)), 1))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(text), 1))
                                  ]),
                                  _: 2
                                }, 1032, ["columns", "data-source"])
                              ]),
                              _: 2
                            }, 1032, ["titulo"])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "ant-btn ant-btn-ghost ss-botao-novo",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.abrirGrupo(-1)))
                        }, [
                          _createVNode(_component_icone, { nome: "novo" })
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_formacao_preco_grupo_modal, {
          visivel: _ctx.state.exibirGrupo,
          "onUpdate:visivel": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.exibirGrupo) = $event)),
          grupo: _ctx.state.grupoSelecionado,
          "onUpdate:grupo": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.grupoSelecionado) = $event)),
          onConfirmar: _ctx.atualizarGrupo
        }, null, 8, ["visivel", "grupo", "onConfirmar"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
          fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso,
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "apresentarBarraProgresso", "fraseBarraProgresso", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}