import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IFormacaoPreco, IFormacaoPrecoAlteracao } from '@/models/Entidades/Cadastros/Precificacao/IFormacaoPreco';

/**
 * Serviço de Formação de Preço
 * Fornece todas regras de negócios e lógicas relacionado a Formação de Preço.
 */

class ServicoFormacaoPreco implements IServicoBase<IFormacaoPreco> {
    endPoint = 'formacao-preco';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IFormacaoPreco): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IFormacaoPreco): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IFormacaoPreco): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IFormacaoPreco> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async calcularPrecos(empresa: number, alteracoes: IFormacaoPrecoAlteracao[]): Promise<IFormacaoPrecoAlteracao[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/calcular-precos/${empresa}`, alteracoes);
      return result.data;
    }

    public async atualizarPrecos(empresa: number, alteracoes: IFormacaoPrecoAlteracao[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/atualizar-precos/${empresa}`, alteracoes);
      return result.data;
    }

    public async obterAlteracoesPreco(formacaoPreco: number, empresa: number, resumido: boolean): Promise<IFormacaoPrecoAlteracao[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/alteracoes/${formacaoPreco}/empresa/${empresa}/resumido/${resumido}`);
      return result.data;
    }
}
export default ServicoFormacaoPreco;
