
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  IFormacaoPreco, IFormacaoPrecoEmpresa, IFormacaoPrecoGrupo, IFormacaoPrecoGrupoItem,
} from '@/models/Entidades/Cadastros/Precificacao/IFormacaoPreco';
import ServicoFormacaoPreco from '@/servicos/Cadastros/Precificacao/ServicoFormacaoPreco';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import FormacaoPrecoGrupoModal from './FormacaoPrecoGrupoModal.vue';
import { ETipoValorFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoValorFinanceiro';
import Card from '@/core/components/Tela/Card.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'FormacaoPrecoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    CampoNumerico,
    FormacaoPrecoGrupoModal,
    Card,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoFormacaoPreco = new ServicoFormacaoPreco();
    telaBase.identificadorRecurso = 'CADASTRO_FORMACAO_PRECO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_FORMACAO_PRECO';

    const state = reactive({
      formacaoPreco: {} as IFormacaoPreco,
      grupoSelecionado: {} as IFormacaoPrecoGrupo,
      indexSelecionado: -1,
      exibirGrupo: false,
      colunas: [] as IColumn[],
    });

    function preencherColunas() {
      state.colunas = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'codigo', position: 1, visible: false, ordering: true,
        },
        {
          title: 'Custo', dataIndex: 'descricao', key: 'descricao', position: 2, visible: true, ordering: true,
        },
        {
          title: 'Valor', dataIndex: 'valor', key: 'valor', position: 3, visible: true, ordering: true, align: 'right',
        },
      ];
    }

    function limparTela() {
      state.formacaoPreco = {} as IFormacaoPreco;
      state.formacaoPreco.margemLucro = 0;
      state.formacaoPreco.empresas = [] as IFormacaoPrecoEmpresa[];
      state.formacaoPreco.grupos = [] as IFormacaoPrecoGrupo[];

      const grupo1 = {} as IFormacaoPrecoGrupo;
      grupo1.descricao = 'Custos Diretos';
      grupo1.tipoValor = ETipoValorFinanceiro.ValorNominal;
      grupo1.itens = [] as IFormacaoPrecoGrupoItem[];

      const grupo2 = {} as IFormacaoPrecoGrupo;
      grupo2.descricao = 'Custos Variáveis';
      grupo2.tipoValor = ETipoValorFinanceiro.Percentual;
      grupo2.itens = [] as IFormacaoPrecoGrupoItem[];

      state.formacaoPreco.grupos.push(grupo1);
      state.formacaoPreco.grupos.push(grupo2);
      telaBase.empresasSelecionadas = [];
      preencherColunas();
    }

    function abrirGrupo(index: number) {
      state.indexSelecionado = index;
      if (index === -1) {
        state.grupoSelecionado = {} as IFormacaoPrecoGrupo;
        state.grupoSelecionado.tipoValor = ETipoValorFinanceiro.Percentual;
        state.grupoSelecionado.itens = [] as IFormacaoPrecoGrupoItem[];
        state.exibirGrupo = true;
      } else {
        state.grupoSelecionado = state.formacaoPreco.grupos[index];
        state.exibirGrupo = true;
      }
    }

    function excluirGrupo(index: number) {
      state.formacaoPreco.grupos.splice(index, 1);
    }

    function atualizarGrupo(grupo: IFormacaoPrecoGrupo) {
      if (state.indexSelecionado === -1) {
        state.formacaoPreco.grupos.push(grupo);
      } else {
        state.formacaoPreco.grupos[state.indexSelecionado] = grupo;
      }
      state.indexSelecionado = -1;
      state.grupoSelecionado = {} as IFormacaoPrecoGrupo;
    }

    function obterFormacao() {
      state.formacaoPreco.empresas = [] as IFormacaoPrecoEmpresa[];
      telaBase.empresasSelecionadas.forEach((e) => {
        const empresa = {} as IFormacaoPrecoEmpresa;
        empresa.codigoFormacaoPreco = state.formacaoPreco.codigo;
        empresa.codigoEmpresa = e;
        state.formacaoPreco.empresas.push(empresa);
      });
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      obterFormacao();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoFormacaoPreco.incluir(state.formacaoPreco);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoFormacaoPreco.alterar(state.formacaoPreco);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso('Registro atualizado com sucesso!');
        if (salvarNovo) {
          limparTela();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar || props.operacao.codigoRegistroDuplicar > 0) {
          const codigo = props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar ? props.operacao.codigoRegistro : props.operacao.codigoRegistroDuplicar;
          state.formacaoPreco = await servicoFormacaoPreco.obter(codigo, props.operacao.empresaSelecionada);
          if (state.formacaoPreco.empresas.length > 0) {
            state.formacaoPreco.empresas.forEach((formacaoPrecoEmpresa) => {
              telaBase.empresasSelecionadas.push(formacaoPrecoEmpresa.codigoEmpresa);
            });
          }
          if (props.operacao.codigoRegistroDuplicar > 0) {
            state.formacaoPreco.codigo = 0;
            state.formacaoPreco.grupos.forEach((g, indexG) => {
              state.formacaoPreco.grupos[indexG].codigo = 0;
              state.formacaoPreco.grupos[indexG].codigoFormacaoPreco = 0;
              state.formacaoPreco.grupos[indexG].itens.forEach((i, indexI) => {
                state.formacaoPreco.grupos[indexG].itens[indexI].codigo = 0;
                state.formacaoPreco.grupos[indexG].itens[indexI].codigoFormacaoPrecoGrupo = 0;
              });
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioMascara,
      abrirGrupo,
      atualizarGrupo,
      excluirGrupo,
      salvar,
    };
  },
});
