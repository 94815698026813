import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_tela_padrao_crud = _resolveComponent("tela-padrao-crud")!
  const _component_formacao_preco_modal = _resolveComponent("formacao-preco-modal")!
  const _component_aplicar_formacao_preco_modal = _resolveComponent("aplicar-formacao-preco-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tela_padrao_crud, {
      ref: "refCrud",
      estrutura: _ctx.crudBase.estrutura,
      servicoAPI: _ctx.servicoFormacaoPreco,
      onExibirModal: _ctx.exibirModal,
      onSincronizaTelaCrud: _ctx.sincronizaTelaCrud
    }, {
      BotoesAdicionais: _withCtx(() => [
        _createElementVNode("button", {
          class: "ant-btn ant-btn-secondary ss-btn-recursos",
          title: "Aplicar Formação de Preço",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.abrirAplicacao && _ctx.abrirAplicacao(...args)))
        }, [
          _createVNode(_component_icone, { nome: "calculadora" })
        ])
      ]),
      _: 1
    }, 8, ["estrutura", "servicoAPI", "onExibirModal", "onSincronizaTelaCrud"]),
    _createVNode(_component_formacao_preco_modal, {
      visivel: _ctx.crudBase.exibirModal,
      "onUpdate:visivel": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.crudBase.exibirModal) = $event)),
      operacao: _ctx.crudBase.operacao,
      onSincronizarRegistro: _ctx.sincronizarRegistro
    }, null, 8, ["visivel", "operacao", "onSincronizarRegistro"]),
    _createVNode(_component_aplicar_formacao_preco_modal, {
      visivel: _ctx.state.exibirAplicacao,
      "onUpdate:visivel": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.exibirAplicacao) = $event))
    }, null, 8, ["visivel"])
  ], 64))
}