
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  IFormacaoPreco, IFormacaoPrecoAlteracao, IFormacaoPrecoGrupo,
} from '@/models/Entidades/Cadastros/Precificacao/IFormacaoPreco';
import ServicoFormacaoPreco from '@/servicos/Cadastros/Precificacao/ServicoFormacaoPreco';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Card from '@/core/components/Tela/Card.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarFormacaoPreco from '@/components/Cadastros/Precificacao/SelecionarFormacaoPreco.vue';
import storeSistema from '@/store/storeSistema';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarItemTabelaPrecoProduto from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarItemTabelaPrecoProduto.vue';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoValorFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoValorFinanceiro';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import BuscarProdutosModal from '@/components/Cadastros/Produtos/BuscarProdutosModal.vue';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';

export default defineComponent({
  name: 'AplicarFormacaoPrecoModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    CampoNumerico,
    Card,
    SelecionarFormacaoPreco,
    MensagemSemDados,
    SelecionarProduto,
    SelecionarItemTabelaPrecoProduto,
    BuscarProdutosModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemErro,
      apresentarMensagemAlerta, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase
      ,
    } = useModalBase(props, emit);
    const servicoFormacaoPreco = new ServicoFormacaoPreco();
    const servicoTabelaPreco = new ServicoTabelaPreco();
    telaBase.identificadorRecurso = 'CADASTRO_FORMACAO_PRECO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_FORMACAO_PRECO';

    const state = reactive({
      formacaoPrecoSelecionada: 0,
      formacaoPreco: {} as IFormacaoPreco,
      parmetrosConsulta: {} as IParametrosConsultaProdutoDefinicao,
      colunasGrupos: [] as IColumn[],
      alteracoes: [] as IFormacaoPrecoAlteracao[],
      exibirBusca: false,
    });

    function preencherColunas() {
      state.colunasGrupos = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'codigo', position: 1, visible: false, ordering: true,
        },
        {
          title: 'Custo', dataIndex: 'descricao', key: 'descricao', position: 2, visible: true, ordering: true,
        },
        {
          title: 'Valor', dataIndex: 'valor', key: 'valor', position: 3, visible: true, ordering: true, align: 'right',
        },
      ];
    }

    function limparTela() {
      state.formacaoPrecoSelecionada = 0;
      state.formacaoPreco = {} as IFormacaoPreco;
      state.formacaoPreco.margemLucro = 0;
      state.formacaoPreco.grupos = [] as IFormacaoPrecoGrupo[];
      state.alteracoes = [] as IFormacaoPrecoAlteracao[];
      state.parmetrosConsulta = {} as IParametrosConsultaProdutoDefinicao;
      state.parmetrosConsulta.apenasAtivos = true;
      state.parmetrosConsulta.retornarApenasCodigoDefinicoes = true;
      state.exibirBusca = false;
      preencherColunas();
    }

    function obterCustoAdicional() {
      let custoAdicional = 0;

      state.formacaoPreco.grupos.forEach((g) => {
        if (g.tipoValor === ETipoValorFinanceiro.ValorNominal) {
          g.itens.forEach((i) => {
            custoAdicional += i.valor;
          });
        }
      });
      return custoAdicional;
    }

    function obterIncidencias() {
      let custoAdicional = 0;

      state.formacaoPreco.grupos.forEach((g) => {
        if (g.tipoValor === ETipoValorFinanceiro.Percentual) {
          g.itens.forEach((i) => {
            custoAdicional += i.valor;
          });
        }
      });
      return custoAdicional;
    }

    function atualizarProduto(index: number) {
      state.alteracoes[index].codigoTabelaPrecoItemProduto = 0;
      state.alteracoes[index].custo = 0;
      state.alteracoes[index].precoAnterior = 0;
      state.alteracoes[index].precoNovo = 0;
    }

    async function obterFormacaoPreco() {
      if (state.formacaoPrecoSelecionada > 0) {
        state.formacaoPreco = await servicoFormacaoPreco.obter(state.formacaoPrecoSelecionada, storeSistema.getters.codigoEmpresaOperacao());
        state.alteracoes.forEach((a, index) => {
          state.alteracoes[index].codigoFormacaoPreco = state.formacaoPrecoSelecionada;
          state.alteracoes[index].margemLucro = state.formacaoPreco.margemLucro;
          state.alteracoes[index].custoAdicional = obterCustoAdicional();
          state.alteracoes[index].incidencias = obterIncidencias();
          state.alteracoes[index].markup = 0;
          state.alteracoes[index].precoNovo = 0;
        });
      } else {
        state.formacaoPreco = {} as IFormacaoPreco;
        state.formacaoPreco.margemLucro = 0;
        state.formacaoPreco.grupos = [] as IFormacaoPrecoGrupo[];
      }
    }

    async function obterCustoProduto(index: number) {
      if (state.alteracoes[index].codigoProdutoDefinicao > 0 && state.alteracoes[index].codigoTabelaPrecoItemProduto > 0) {
        state.alteracoes[index].custo = await servicoTabelaPreco.obterCustoProduto(state.alteracoes[index].codigoTabelaPrecoItemProduto);
        state.alteracoes[index].precoAnterior = await servicoTabelaPreco.obterPrecoProduto(state.alteracoes[index].codigoTabelaPrecoItemProduto);
      } else {
        state.alteracoes[index].custo = 0;
        state.alteracoes[index].precoAnterior = 0;
      }
    }

    function excluirAlteracao(index: number) {
      state.alteracoes.splice(index, 1);
    }

    function inserirAlteracoes(definicoes: number[]) {
      definicoes.forEach((d) => {
        const alteracao = {} as IFormacaoPrecoAlteracao;
        alteracao.codigoProdutoDefinicao = d;
        alteracao.codigoFormacaoPreco = state.formacaoPrecoSelecionada;
        alteracao.margemLucro = state.formacaoPreco.margemLucro;
        alteracao.custoAdicional = obterCustoAdicional();
        alteracao.incidencias = obterIncidencias();
        state.alteracoes.push(alteracao);
      });
    }

    function abrirBuscaProdutos() {
      state.exibirBusca = true;
    }

    async function calcularPrecos() {
      let validacao = true;

      if (state.formacaoPrecoSelecionada === 0) {
        apresentarMensagemAlerta('A formação de preço deve ser informada!');
        return;
      }

      state.alteracoes.forEach((a, index) => {
        if (!UtilitarioGeral.validaCodigo(state.alteracoes[index].codigoProdutoDefinicao)) {
          apresentarMensagemAlerta('O produto deve ser informado para a realização do cálculo!');
          validacao = false;
        }
        if (!UtilitarioGeral.validaCodigo(state.alteracoes[index].codigoTabelaPrecoItemProduto)) {
          apresentarMensagemAlerta('A tabela de preço deve ser informada para a realização do cálculo!');
          validacao = false;
        }
      });
      if (!validacao) return;

      const alteracoes = await servicoFormacaoPreco.calcularPrecos(storeSistema.getters.codigoEmpresaOperacao(), state.alteracoes);

      if (UtilitarioGeral.validaLista(alteracoes)) {
        state.alteracoes = alteracoes;
      } else {
        apresentarMensagemErro('Ocorreu um problema ao calcular os preços!');
      }
    }

    async function atualizarPrecos() {
      let validacao = true;

      if (state.formacaoPrecoSelecionada === 0) {
        apresentarMensagemAlerta('A formação de preço deve ser informada!');
        validacao = false;
      }

      state.alteracoes.forEach((a, index) => {
        if (!UtilitarioGeral.validaCodigo(state.alteracoes[index].precoNovo)) {
          apresentarMensagemAlerta('Um dos produtos está com o novo preço zerado!');
          validacao = false;
        }
      });
      if (validacao) {
        const retorno = await servicoFormacaoPreco.atualizarPrecos(storeSistema.getters.codigoEmpresaOperacao(), state.alteracoes);

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          apresentarMensagemSucesso('Preços atualizados com sucesso!');
          limparTela();
        } else {
          apresentarMensagemErro('Ocorreu um problema ao atualizar os preços!');
        }
      }
    }

    async function confirmarAtualizacao() {
      Modal.confirm({
        title: 'Realmente deseja atualizar os preços?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await atualizarPrecos(); },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      // if (modalBase.computedVisivel) {
      // }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      storeSistema,
      UtilitarioGeral,
      UtilitarioMascara,
      obterFormacaoPreco,
      excluirAlteracao,
      obterCustoProduto,
      calcularPrecos,
      atualizarPrecos,
      atualizarProduto,
      confirmarAtualizacao,
      inserirAlteracoes,
      abrirBuscaProdutos,
    };
  },
});
